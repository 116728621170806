import React from 'react'
import Select from '../Select'

class Specialists extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeField: 'All',
      activeSpecialist: '',
      specialists: '',
      windowWidth: null,
      searchActive: null,
    }
  }

  updateWindowWidth = () => {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount = () => {
    this.updateWindowWidth()
    window.addEventListener('resize', this.updateWindowWidth)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowWidth)
  }

  toggleField = item => {
    this.setState({ activeField: item, specialists: '', searchActive: false })
    this.refs.search.value = ''
  }

  toggleSpecialist = (first, last) => {
    this.setState({ activeSpecialist: first + ' ' + last })
  }

  inputHandler = (e, specialistsObj) => {
    this.setState({
      activeField: 'All',
      activeSpecialist: '',
      searchActive: true,
    })

    let val = e.target.value.toLowerCase()

    let result = specialistsObj.filter(
      item =>
        item.last_name.toLowerCase().includes(val) ||
        item.first_name.toLowerCase().includes(val) ||
        item.full_name.toLowerCase().includes(val)
    )

    this.setState({ specialists: result })

    if (val === '') {
      this.setState({
        searchActive: false,
      })
    }
  }

  onSelect = e => {
    let select = e.target.value
    this.setState({ activeField: select, specialists: '' })
    this.refs.search.value = ''
  }

  render() {
    var specialistsObj = this.props.specialistContent.specialists
      .filter(item => item.first_name !== null && item.last_name !== null)
      .map((item, i) => {
        return {
          last_name: item.last_name,
          first_name: item.first_name,
          full_name: item.first_name + ' ' + item.last_name,
          field: item.field,
          website: item.link,
        }
      })

    let fields = []

    specialistsObj.forEach(item => {
      fields.push(item.field)
    })

    let uniqueFields = Array.from(new Set(fields))

    uniqueFields.push('All')

    uniqueFields.sort(function(a, b) {
      if (a < b) {
        return -1
      }
      if (a > b) {
        return 1
      }
      return 0
    })

    let filteredObj
    if (this.state.specialists === '') {
      filteredObj = specialistsObj.filter(item =>
        this.state.activeField && this.state.activeField !== 'All'
          ? this.state.activeField === item.field
          : item
      )
    } else {
      filteredObj = this.state.specialists
    }

    filteredObj.sort(function(a, b) {
      var keyA = a.last_name.toLowerCase(),
        keyB = b.last_name.toLowerCase()

      if (keyA < keyB) {
        return -1
      }
      if (keyA > keyB) {
        return 1
      }
      return 0
    })

    if (this.state.windowWidth > 1400) {
      var second, third, columns
      var columnOne, columnTwo, columnThree
      second = Math.ceil(filteredObj.length / 3)
      third = Math.ceil((2 * filteredObj.length) / 3)

      columnOne = filteredObj.slice(0, second)
      columnTwo = filteredObj.slice(second, third)
      columnThree = filteredObj.slice(third, filteredObj.length)

      columns = [columnOne, columnTwo, columnThree]
    } else {
      var indexToSplit = Math.ceil(filteredObj.length / 2)
      var first = filteredObj.slice(0, indexToSplit)
      second = filteredObj.slice(indexToSplit)
      columns = [first, second]
    }

    return (
      <div
        id="specialists"
        className="mb-8 leading-normal text-sm tracking-sm px-3 lg:px-14 pt-4 pb-12 pt-12 lg:py-32 bg-grey-5"
      >
        <div className="hidden lg:flex lg:-mx-2 mb-14">
          <div className="lg:w-1/2 lg:px-2">
            <div className="lg:flex lg:-mx-2">
              <div className="lg:w-1/3 lg:px-2">
                <p className="font-extrabold text-sm">Search</p>
              </div>
              <div className="lg:w-2/3 lg:px-2">
                <p className="font-extrabold text-sm">Specialist field</p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 lg:px-2">
            <p className="font-extrabold text-sm">A – Z surname</p>
          </div>
        </div>
        <div className="lg:flex lg:-mx-2">
          <div className="lg:w-1/2 lg:px-2">
            <div className="lg:flex lg:-mx-2">
              <div className="lg:w-1/3 lg:px-2 pb-12">
                {this.state.windowWidth > 990 ? (
                  <input
                    ref="search"
                    placeholder="Type here"
                    onChange={e => {
                      this.inputHandler(e, specialistsObj)
                    }}
                    className="w-48 lg:w-3/4 bg-transparent outline-none"
                    type="text"
                  />
                ) : (
                  <input
                    ref="search"
                    placeholder="Type specialists name here"
                    onChange={e => {
                      this.inputHandler(e, specialistsObj)
                    }}
                    className="w-48 lg:w-3/4 bg-transparent outline-none"
                    type="text"
                  />
                )}
              </div>
              <div className="pb-12 lg:pb-0 lg:w-2/3 lg:px-2">
                <ul className="hidden lg:block list-reset">
                  {uniqueFields.map((item, i) => {
                    return (
                      <li key={i} className="py-1">
                        <button
                          onClick={() => {
                            this.toggleField(item)
                          }}
                          className={
                            this.state.activeField === item
                              ? 'specialist__field specialist__field--is-active'
                              : 'specialist__field'
                          }
                        >
                          {item}
                        </button>
                      </li>
                    )
                  })}
                </ul>
                <Select
                  uniqueFields={uniqueFields}
                  onSelect={this.onSelect}
                  activeField={this.state.activeField}
                />
              </div>
            </div>
          </div>
          <div className="lg:hidden mb-4">
            <p className="font-extrabold text-sm">A – Z surname</p>
          </div>
          <div className="flex lg:w-1/2 lg:px-2 text-sm">
            {columns.map((column, i) => (
              <ul key={i} className="w-1/2 xxl:w-1/3 list-reset">
                {column.map((specialist, i) => {
                  return (
                    <li
                      key={i}
                      className="leading-tight relative lg:mx-0 py-1 group"
                    >
                      {specialist.website ? (
                        <>
                          <a
                            href={specialist.website.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex w-full specialist__name pr-4 sm:w-3/4 sm:pr-0 lg:w-4/5 no-underline text-charcoal transition group-hover:font-medium"
                          >
                            <p className="block pr-1">
                              {specialist.first_name} {specialist.last_name}
                            </p>
                            <svg
                              className="opacity-50 group-hover:opacity-100 transition"
                              width="8"
                              height="8"
                              viewBox="0 0 8 8"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.111 7.111H.89V.89H4V0H.889C.396 0 0 .4 0 .889V7.11C0 7.6.396 8 .889 8H7.11C7.6 8 8 7.6 8 7.111V4h-.889v3.111zM4.89 0v.889h1.595L2.116 5.258l.626.626 4.37-4.368V3.11H8V0H4.889z"
                                fill="#666D70"
                                fillRule="nonzero"
                              />
                            </svg>
                          </a>
                        </>
                      ) : (
                        <p className="specialist__name pr-12">
                          {specialist.first_name} {specialist.last_name}
                        </p>
                      )}

                      {this.state.searchActive && (
                        <p className="block pr-1 text-xsm text-grey pb-1">
                          {specialist.field}
                        </p>
                      )}
                    </li>
                  )
                })}
              </ul>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default Specialists

import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Intro from '../components/intro'
import Building from '../components/Building'
import Specialists from '../components/Specialists'
import TextBlock from '../components/TextBlock'
import birdsImageMobile from '../images/birds-mobile.png'

import SEOPage from '../components/SEO/Page'
import { formatId } from '../utils/format-id'

class IndexPage extends React.Component {
  render() {
    const { location, data } = this.props
    const specialistContent = data.prismicSpecialists.data
    var heroImgs = []
    var heroImgsMobile = []

    specialistContent.hero.forEach(item => {
      heroImgs.push(item.desktop_image.url)
      heroImgsMobile.push(item.mobile_image.url)
    })

    return (
      <Layout>
        <SEOPage
          title={specialistContent.title && specialistContent.title}
          location={location}
        />
        <Hero heroImgs={heroImgs} heroImgsMobile={heroImgsMobile} />
        <Intro
          id="specialists-at-forte"
          title={specialistContent.lead_title}
          lead={specialistContent.lead_text.html}
        />

        <Building
          id={formatId(specialistContent.building_primary_title)}
          title={specialistContent.building_primary_title}
          lead={specialistContent.building_primary_text.html}
          img={specialistContent.building_primary_image.url}
        >
          {specialistContent.services_primary.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {item.service_primary.html && (
                  <div
                    className="md:w-1/3 mb-8 leading-normal text-sm tracking-sm px-2 rte break-words"
                    dangerouslySetInnerHTML={{
                      __html: item.service_primary.html,
                    }}
                  />
                )}
              </React.Fragment>
            )
          })}
        </Building>
        <Building
          id={formatId(specialistContent.building_secondary_title)}
          title={specialistContent.building_secondary_title}
          lead={specialistContent.building_secondary_text.html}
          img={specialistContent.building_secondary_image.url}
        >
          {specialistContent.services_secondary.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {item.service_secondary.html && (
                  <div
                    className="md:w-1/3 mb-8 leading-normal text-sm tracking-sm px-2 rte break-words"
                    dangerouslySetInnerHTML={{
                      __html: item.service_secondary.html,
                    }}
                  />
                )}
              </React.Fragment>
            )
          })}
        </Building>

        <div className="mb-24 lg:mb-48 pt-24 lg:pt-0 relative">
          {specialistContent.operating_image.url && (
            <div className="-mx-32 lg:mx-0">
              <img
                src={specialistContent.operating_image.url}
                alt={specialistContent.operating_image.alt}
              />
            </div>
          )}
          <div className="mt-0 lg:-mb-32 pl-3 lg:pl-14 lg:w-1/2 absolute pin-t pin-l lg:w-full lg:h-full">
            <div className="lg:flex lg:justify-end lg:items-end lg:h-full">
              <div className="w-3/5 lg:w-1/3 lg:mr-12 lg:-mb-24">
                <img src={birdsImageMobile} alt="Birds" />
              </div>
            </div>
          </div>
        </div>

        <TextBlock
          title={specialistContent.operating_title}
          lead={specialistContent.operating_text.html}
        />

        <Specialists specialistContent={specialistContent} />
      </Layout>
    )
  }
}

export const pageSpecialistsQuery = graphql`
  query {
    prismicSpecialists {
      data {
        title
        hero {
          desktop_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
          mobile_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
        }
        lead_title
        lead_text {
          html
        }
        building_primary_title
        building_primary_text {
          html
        }
        building_primary_image {
          url
        }
        building_secondary_title
        building_secondary_text {
          html
        }
        building_secondary_image {
          url
        }
        services_primary {
          service_primary {
            html
          }
        }
        services_secondary {
          service_secondary {
            html
          }
        }
        operating_title
        operating_text {
          html
        }
        operating_image {
          url
          alt
          localFile {
            childImageSharp {
              id
            }
          }
        }
        specialists {
          last_name
          first_name
          field
          link {
            url
          }
        }
      }
    }
  }
`

export default IndexPage

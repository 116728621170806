import React from 'react'

class Select extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 'All',
    }
  }

  render() {
    const { uniqueFields, onSelect } = this.props
    return (
      <select
        className="select lg:hidden bg-grey-5 border px-4 py-3 w-full md:w-1/2"
        ref="select"
        value={this.props.activeField}
        onBlur={e => {
          onSelect(e)
        }}
        onChange={e => {
          onSelect(e)
        }}
      >
        {uniqueFields.map((item, i) => {
          return (
            <option key={i} value={item}>
              {item}
            </option>
          )
        })}
      </select>
    )
  }
}

export default Select

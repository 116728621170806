import React from 'react'

// eslint-disable-next-line
var backgroundStyle

const Building = ({ title, lead, img, children, id }) => (
  <div id={id} className="mb-8 lg:mb-35 overflow-x-hidden">
    <div className="lg:flex -mx-2 items-center mb-16">
      {title && (
        <div className="lg:w-1/2 px-2">
          <div className="px-3 lg:px-14">
            <h3 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl text-grey font-light leading-tight">
              {title}
            </h3>
          </div>
        </div>
      )}
      <div className="lg:w-1/2 px-2">
        <div
          className="ratio ratio-5/7"
          style={
            (backgroundStyle = {
              backgroundImage: `url(${img})`,
              backgroundSize: `cover`,
            })
          }
        />
      </div>
    </div>
    <div className="px-3 lg:px-14">
      <div className="lg:flex -mx-2">
        {lead && (
          <div className="lg:w-1/2 px-2">
            <div
              className="lg:w-3/5 text-2xl text-grey font-light leading-tight rte mb-16"
              dangerouslySetInnerHTML={{ __html: lead }}
            />
          </div>
        )}
        <div className="lg:w-1/2 px-2">
          <div className="md:flex md:flex-wrap -mx-2">{children}</div>
        </div>
      </div>
    </div>
  </div>
)

export default Building
